import {
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaLinkedin,
} from "react-icons/fa"; // Import Font Awesome icons
import { Link } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi"; // Import the arrow icon
import { BackgroundBeams } from "../Components/ui/background-beams";
// import Logo from "../media/prodlogo.png";
import Logo from "../media/tagline_2.png";
import { HashLink as Hlink } from "react-router-hash-link";
import Chrome from "../media/getonchrome.jpg";

export default function Footer() {
  //scroll to 90px above targer element
  const scrollWithOffset = (el) => {
    const yOffset = -90; // Adjust for navbar height
    const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <section
      className="top-full w-screen bg-black"
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      <footer
        className="relative bg-black px-10 mb-10"
        style={{
          width: "100%",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Get Started Section */}
        <div
          className="relative  text-justify xl:text-left bg-black backdrop-blur-xl rounded-xl shadow-2xl p-8 flex flex-col justify-center items-center bg-neutral-900 bg-opacity-70 antialiased z-10"
          style={{ overflow: "hidden" }} // Make sure background beams stay within this container
        >
          <p className="text-white text-4xl mb-8 z-10">
            <strong>Still Unsure?? </strong>Take the leap and multiply your
            success with{" "}
            <strong className="text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
              ProdNX!{" "}
            </strong>
            {/* your productivity! */}
          </p>
          <button
            type="button"
            className="relative group flex text-lg items-center justify-start focus:outline-none font-medium rounded-10  space-x-2
              bg-gradient-to-br bg-white text-white z-20"
            style={{
              transition: "transform 0.1s ease-out", // Smooth transition for better effect
            }}
          >
            <a
              href="https://chromewebstore.google.com/detail/productivity-tracker/ihfjboopehfdmedfagockddklomdmglh"
              className=" flex items-center  space-x-3 rtl:space-x-reverse"
            >
              {/* <span className="font-bold text-4xl pb-[4px]">Starter</span> */}
              <img
                src={Chrome} // Use the imported logo here
                alt="Starter 101 Logo"
                className="w-48 h-12" // Adjust size as needed
              />
            </a>
            {/* Available on Chrome Web Store */}
          </button>

          {/* Background Beams for the right section */}
          <div className="absolute inset-0 z-0 w-full h-full overflow-hidden">
            <BackgroundBeams />
          </div>
        </div>

        {/* Background overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-xl"></div>

        {/* Content wrapper */}
        <div className="relative z-10 container mx-auto text-white px-4">
          <div className="my-10 text-center border-t border-gray-500 "></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center md:text-left">
            {/* Logo or Brand Name */}
            <div>
              {/* <h3 className="text-3xl font-bold">Starter 101</h3> */}
              <Hlink smooth to="/#">
                <a className=" flex items-center  space-x-3 rtl:space-x-reverse">
                  {/* <span className="font-bold text-4xl pb-[4px]">Starter</span> */}
                  <img
                    src={Logo} // Use the imported logo here
                    alt="Starter 101 Logo"
                    className="w-full h-full" // Adjust size as needed
                  />
                </a>
              </Hlink>
              {/* <p className="text-md mt-2 text-justify">
                Learn robotics, electronics, coding, and more — build hands-on
                projects, solve real-world problems, and unlock your potential
                in the fields of tech and automation.
              </p> */}
            </div>

            {/* logo desc */}
            <div className=" flex justify-center lg:justify-start items-center space-x-6">
              <p className="text-md mt-2 text-justify">
                Unleash your potential! 📈🔥 Get ready to 🚀 boost productivity,
                track web time, stay focused, and optimize your workflow with
                this powerful Chrome Extension. Achieve more, stay on track, and
                make every minute count. ✨
              </p>
            </div>

            {/* Quick Links */}
            <div className="flex flex-col justify-center items-center">
              <h4 className="text-xl  font-semibold">Quick Links</h4>
              <ul className="flex flex-col justify-center items-center space-y-2 mt-2">
                {/* <li>
                    <a
                      href="/about"
                      className="hover:text-lime-500 transition-colors duration-200"
                    >
                      About Us
                    </a>
                  </li> */}

                <Hlink smooth to="/#features" scroll={scrollWithOffset}>
                  <li>
                    <a className="hover:text-lime-500 transition-colors duration-200">
                      Features
                    </a>
                  </li>
                </Hlink>
                <Hlink smooth to="/#how-it-works" scroll={scrollWithOffset}>
                  <li>
                    <a className="hover:text-lime-500 transition-colors duration-200">
                      How it works?
                    </a>
                  </li>
                </Hlink>
                {/* <Hlink smooth to="/#reviews">
                  <li>
                    <a className="hover:text-lime-500 transition-colors duration-200">
                      Reviews
                    </a>
                  </li>
                </Hlink> */}

                <Link to="/policy">
                  <li>
                    <a className="hover:text-lime-500 transition-colors duration-200">
                      Privacy Policy
                    </a>
                  </li>
                </Link>
                <Hlink smooth to="/#faq" scroll={scrollWithOffset}>
                  <li>
                    <a
                      href="/faq"
                      className="hover:text-lime-500 transition-colors duration-200"
                    >
                      FAQ
                    </a>
                  </li>
                </Hlink>
              </ul>
            </div>

            {/* Contact Info */}
            <div className="flex flex-col justify-center align-center">
              <h4 className="text-xl font-semibold mb-2 ">Contact</h4>
              <p className="mb-2">
                <a href="mailto:setia.lakshay@outlook.com">
                  Email: setia.lakshay@outlook.com
                </a>
              </p>
              {/* <p>Phone: Available after registration</p> */}
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-10 text-center border-t border-gray-500 pt-5">
            <p className="text-gray-400">
              &copy; 2025 ProdNx. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
}
