import { useEffect } from "react";
import Navbar from "../Components/Navbar";
import FooterPolicy from "../Components/FooterPolicy.js";
import Analytics from "../Components/Analytics";
import { ShootingStars } from "../Components/ui/shooting-stars.jsx";
import { StarsBackground } from "../Components/ui/stars-background";
import Error404 from "../Components/Error404.js";

function RegisterPage() {
  // // Prevent scrolling on the body
  // useEffect(() => {
  //   document.body.style.overflow = "hidden"; // Disable scrolling on the main page
  //   return () => {
  //     document.body.style.overflow = "auto"; // Re-enable scrolling when component unmounts
  //   };
  // }, []);

  // Scroll to the top when the component mounts

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-col h-screen">
      <div
        style={{
          position: "fixed", // Position absolutely within the viewport
          top: 0,
          left: 0,
          width: "100vw", // Full width of the viewport
          maxWidth: "100vw", // Full width of the viewport
          height: "100vh", // Full height of the viewport
          overflow: "hidden", // Hide any extra overflow
          zIndex: "-1", // Send it to the background
          backgroundColor: "black",
        }}
      >
        <ShootingStars />
        <StarsBackground />
      </div>
      <Analytics />
      {/* Fix the navbar at the top */}
      <div className="fixed top-0 w-full  z-50">
        <Navbar />
      </div>

      {/* Ensure the Register component fills the remaining space */}
      <div className="mt-16 h-full">
        {" "}
        {/* Adjust the mt-16 based on your navbar height */}
        <Error404 />
        <div className="mt-16 h-fit">
          <FooterPolicy />
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
