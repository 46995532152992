import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen font-sans text-white">
      <div className="container mx-auto p-6 mt-10">
        <div className="bg-white bg-opacity-10 p-6 rounded-lg shadow-lg ">
          <h1 className="text-3xl font-bold text-center mb-6 text-[#bdcf32]">
            Privacy Policy
          </h1>

          <p className="text-lg mb-4">
            <strong>Last Updated:</strong> 22nd November 2024
          </p>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Introduction
            </h2>
            <p className="mt-2 text-white">
              Welcome to <strong>ProdNX</strong>, a productivity tracker Chrome
              Extension designed to help you enhance your time management and
              boost productivity. At ProdNX, your privacy is our top priority.
              This privacy policy explains how we collect, use, and protect your
              data when you use our Chrome Extension. Please read it carefully.
            </p>
            <p className="mt-2 text-white">
              By installing and using ProdNX, you agree to the collection and
              use of information in accordance with this policy.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              What Does ProdNX Do?
            </h2>
            <p className="mt-2 text-white">
              ProdNX helps users track their web time, categorize websites as
              productive or non-productive, and gain insights into their
              browsing habits through intuitive visual representations, such as
              doughnut graphs. The extension offers personalized productivity
              tracking and a range of features to improve time management,
              personalized productivity, and fun games to keep you focused.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Data Collection and Usage
            </h2>
            <p className="mt-2 text-white">
              We respect your privacy, which is why ProdNX does{" "}
              <strong>not collect, store, or share</strong> any personally
              identifiable information or user data. All data generated by the
              extension, including time tracking and website categorization, is
              stored <strong>locally</strong> on your device and{" "}
              <strong>never</strong> transferred to external servers.
            </p>

            <h3 className="mt-4 font-semibold">What We Do NOT Collect:</h3>
            <ul className="list-disc pl-5 mt-2">
              <li>
                Personal information: No personally identifiable information is
                collected or shared.
              </li>
              <li>
                Browsing history: ProdNX does not track or store your browsing
                history outside of the time spent on each website for
                productivity tracking purposes.
              </li>
              <li>
                Data sharing: We do not share or sell any data to third parties.
              </li>
            </ul>

            <h3 className="mt-4 font-semibold">What We Do Collect:</h3>
            <p className="mt-2 text-base">
              First, it's important to clarify that we do not collect any
              personal or sensitive information. The data we mention here is
              strictly used by the extension to function properly and is stored
              locally on your device. None of your data leaves your computer.
            </p>
            <ul className="list-disc pl-5 mt-2">
              <li>
                Local data storage: Information such as the websites you visit,
                time spent on them, and productivity categorization is stored
                locally within your browser’s storage.
              </li>
              <li>
                Usage data: ProdNX may collect non-personally identifiable data
                related to your use of the extension, such as the frequency of
                usage, features accessed, and performance metrics. This data is
                collected solely for the purpose of improving the extension’s
                functionality and user experience.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">Security</h2>
            <p className="mt-2 text-white">
              The security of your data is paramount. ProdNX stores all data
              locally in your browser's storage, protected by the same security
              measures used by Chrome for your browsing history. We do not
              maintain servers or datawhites, so your data is never stored off
              your device. We ensure that your data remains private and secure
              through the robust security features built into the Chrome
              browser.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Data Deletion
            </h2>
            <p className="mt-2 text-white">
              You have complete control over your data. If you wish to delete
              all data generated by ProdNX, simply uninstall the extension. All
              data stored in your browser will be erased. We are also working on
              a feature that will allow you to delete your data directly from
              the extension without uninstalling it. Stay tuned for updates!
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Can I Recover My Data?
            </h2>
            <p className="mt-2 text-white">
              Once you uninstall the extension, your data is permanently
              deleted. As ProdNX does not store your data on external servers,
              recovery is not possible. We recommend backing up any important
              data before uninstalling.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Syncing Across Devices
            </h2>
            <p className="mt-2 text-white">
              Currently, ProdNX does not support syncing data across multiple
              devices. All data is stored locally on your browser, and there is
              no server-whited syncing functionality. We prioritize privacy and
              avoid storing your data on external servers to maintain user
              privacy and security.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              No Selling of Data
            </h2>
            <p className="mt-2 text-white">
              ProdNX does not sell or share any user data. Your privacy is our
              priority, and we are committed to providing you with a fully
              privacy-conscious experience. We do not engage in any data
              monetization practices.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Children’s Privacy
            </h2>
            <p className="mt-2 text-white">
              ProdNX does not knowingly collect personal information from
              children under the age of 13. All data utilized by the app is
              securely stored locally on the user's own device. Children,
              parents, or guardians can easily delete this data by uninstalling
              the extension. As we have no access to the stored data, we cannot
              view, modify, or retrieve it in any way.
            </p>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Compliance with Laws
            </h2>
            <p className="mt-2 text-white">
              ProdNX is committed to adhering to applicable privacy laws,
              including the General Data Protection Regulation (GDPR) and the
              California Consumer Privacy Act (CCPA). As our extension does not
              collect or process personal data, it is designed to maintain
              compliance with these regulations by ensuring all data remains
              securely stored on the user's local device. We respect user
              privacy and uphold the highest standards to protect it.
            </p>
          </section>
          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Changes to This Privacy Policy
            </h2>
            <p className="mt-2 text-white">
              We may update this privacy policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. We encourage you to review this privacy policy
              periodically. The updated version will be effective as soon as it
              is posted on this page, and it will be dated with the "Last
              Updated" date.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">
              Your Feedback Matters
            </h2>
            <p className="mt-2 text-white">
              We highly value user feedback, and we are committed to
              continuously improving the ProdNX experience. If you have any
              questions or concerns about your privacy or the features of
              ProdNX, feel free to contact.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold text-[#bdcf32]">Contact</h2>
            <p className="mt-2 text-white">
              If you have any questions about this privacy policy or the way we
              handle your personal information, please contact at:
            </p>
            <ul className="mt-2">
              <li className="text-white">
                <a href="mailto:setia.lakshay@outlook.com">
                  Email: setia.lakshay@outlook.com
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
