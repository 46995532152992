import { AnimatedTestimonials } from "./ui/animated-testimonials";
import Vaibhav from "../media/vaibhav.jpg";
import Naman from "../media/naman.jpg";
import Nipun from "../media/nipun.jpg";
import Sayon from "../media/sayon.jpg";

export default function AnimatedTestimonialsDemo() {
  const testimonials = [
    {
      quote:
        "Just downloaded this. Really helps me figure out where I spent most of my time!",
      name: "Vaibhav",
      // designation: "SDE II at Meta",
      src: Vaibhav,
    },
    {
      quote:
        "What an Extension! Was really in a need to have something which will help me in tracking my daily internet usage. Now I can easily track my daily activities without any concerns of my privacy breach.",
      name: "Nipun Garg",
      // designation: "SDE at Deloitte",
      src: Nipun,
    },
    {
      quote: "Finally found what I needed. Thanks a lot!!",
      name: "Sayon Mukherjee",
      // designation: "DAA at ZS Associates",
      src: Sayon,
    },
    {
      quote:
        "Great extension .!!! Always helping in managing the time and daily activities  and the best part is that it all happened without any security breach and data loss .",
      name: "Naman Jain",
      // designation: "Associate at Celebal",
      src: Naman,
    },
  ];
  return (
    <>
      {/* Background grid component */}
      <div
        id="reviews"
        className="h-fit w-full bg-black bg-opacity-75 relative flex flrx-col items-center justify-center"
      >
        <div className="absolute inset-0  bg-grid-small-white/[0.1]" />

        {/* Black fade overlay for the video */}
        <div className="absolute bottom-0 left-0 right-0 h-1/6 bg-gradient-to-t from-black to-transparent" />
        {/* Black fade overlay for the video */}
        <div className="absolute top-0 left-0 right-0 h-1/6 bg-gradient-to-b from-black to-transparent" />

        <div className="flex flex-col justify-center items-center relative z-10 p-10  ">
          <h2 className="text-5xl md:text-6xl font-bold text-stone-100 text-center mt-8 mb-4 drop-shadow-[0_2.2px_2.2px_rgba(0,0,0,0.9)]">
            Words That Matter!
          </h2>
          <h4 className="text-lg text-white text-center">
            <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
              REAL EXPERIENCES SHARED BY REAL USERS
            </span>{" "}
          </h4>
          <AnimatedTestimonials testimonials={testimonials} />;
        </div>
      </div>
    </>
  );
}
