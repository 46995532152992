import { useState, useEffect } from "react";
import Counter from "./Counter";

function Home() {
  const [scrollY, setScrollY] = useState(0);
  const [mainHeight, setMainHeight] = useState(0);

  // Function to handle scroll event
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  // Function to set the height of the Main section
  const updateMainHeight = () => {
    const mainElement = document.querySelector(".main-section");
    if (mainElement) {
      setMainHeight(mainElement.clientHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateMainHeight); // Update height on resize
    updateMainHeight(); // Set initial height
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateMainHeight);
    };
  }, []);

  // Translate Home section based on scroll position
  const translateY = Math.min(0, scrollY - mainHeight);
  return (
    <>
      <section
        className="top-full"
        style={{
          transform: `translateY(${translateY}px)`,
          position: "relative",
          width: "100%",
          overflow: "hidden",
          transition: "transform 0.5s ease-out",
        }}
      >
        {/* Background grid component */}
        <div className="h-fit w-full bg-black relative flex items-center justify-center">
          <div className="absolute inset-0 bg-black bg-grid-white/[0.5]" />

          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-r from-[#bdcf32] to-[#5c8500] mix-blend-multiply" />
          {/* Black fade overlay for the video */}
          <div className="absolute bottom-0 left-0 right-0 h-1/3 bg-gradient-to-t from-black to-transparent" />
          {/* Black fade overlay for the video */}
          <div className="absolute top-0 left-0 right-0 h-1/3 bg-gradient-to-b from-black to-transparent" />
          {/* Radial gradient for the container to give a faded look */}
          <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>

          <div className="flex flex-col justify-center items-center relative z-10 p-10  ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center justify-center items-stretch w-screen p-20 ">
              {/* Students */}
              <h1 className="text-6xl font-bold text-slate-200">
                <div className=" text-6xl flex justify-center">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#5c8500] to-[#bdcf32] ">
                    <Counter endValue={225} />
                    <span>+</span>
                  </span>
                </div>
                <div className="mt-3 text-2xl font-light text-white flex justify-center">
                  ACTIVE USERS
                </div>
              </h1>

              {/* Projects */}
              {/* <div
                className="bg-gradient-to-br from-white/10 to-white/0 backdrop-blur-md shadow-2xl rounded-lg p-4 flex flex-col justify-center w-full"
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.18)",
                }}
              > */}
              <h1 className="text-6xl font-bold text-slate-200">
                <div className=" text-6xl flex justify-center text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#bdcf32]">
                  <Counter endValue={450} />
                  <span>+</span>
                </div>
                <div className="mt-3 text-2xl font-light flex justify-center text-white">
                  DOWNLOADS
                </div>
              </h1>
              {/* </div> */}

              {/* Experience */}
              {/* <div
                className="bg-gradient-to-br from-white/10 to-white/0 backdrop-blur-md shadow-2xl rounded-lg p-4 flex flex-col justify-center w-full"
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.18)",
                }}
              > */}
              <h1 className="text-6xl font-bold text-slate-200">
                <div className=" text-6xl flex justify-center text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
                  <Counter endValue={57} />
                  <span>+</span>
                </div>
                <div className="mt-3 text-2xl font-light text-white flex justify-center">
                  COUNTRIES
                </div>
              </h1>
              {/* </div> */}
            </div>
            {/* SCROLLER STARTS */}
            {/* <div class="relative flex overflow-x-hidden text-white w-screen font-semibold">
              <div class="py-4 animate-marquee whitespace-nowrap">
                <span class="text-3xl mx-4 text-yellow-400">
                  🍾Launch Offer: 75% OFF 🎉
                </span>
                <span class="text-3xl mx-4 ">🎊 Free Discovery Call 📞</span>
                <span class="text-3xl mx-4 text-yellow-400">
                  🍾Launch Offer: 75% OFF 🎉
                </span>
                <span class="text-3xl mx-4">🎊 Free Discovery Call 📞</span>
              </div>

              <div class="absolute top-0 py-4 animate-marquee2 whitespace-nowrap">
                <span class="text-3xl mx-4 text-yellow-400">
                  🍾Launch Offer: 75% OFF 🎉
                </span>
                <span class="text-3xl mx-4">🎊 Free Discovery Call 📞</span>
                <span class="text-3xl mx-4 text-yellow-400">
                  🍾Launch Offer: 75% OFF 🎉
                </span>
                <span class="text-3xl mx-4">🎊 Free Discovery Call 📞</span>
              </div>
            </div> */}
            {/* SCROLLER ENDS */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
