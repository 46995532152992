import { useEffect } from "react";
// import NavbarReg from "../Components/Navbar";
import Register from "../Components/Feedback";
import Analytics from "../Components/Analytics";

function RegisterPage() {
  // Prevent scrolling on the body
  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrolling on the main page
    return () => {
      document.body.style.overflow = "auto"; // Re-enable scrolling when component unmounts
    };
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <Analytics />
      {/* Fix the navbar at the top */}
      {/* <div className="fixed top-0 bg-black w-full z-50">
        <NavbarReg />
      </div> */}
      {/* Ensure the Register component fills the remaining space */}
      {/* <div className="mt-16 h-full"> */}{" "}
      {/* Adjust the mt-16 based on your navbar height */}
      <Register />
      {/* </div> */}
    </div>
  );
}

export default RegisterPage;
