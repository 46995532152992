import { useState, useEffect } from "react";
import { cn } from "../lib/utils";
import {
  IconClock,
  IconLayoutGrid,
  IconBan,
  IconChartBar,
  IconReportAnalytics,
  IconDownload,
  IconBrain,
  IconLock,
} from "@tabler/icons-react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export function FeaturesSectionDemo() {
  const features = [
    {
      title: "Real-time Activity Tracking",
      description: "Monitor and analyze screen time effortlessly.",
      icon: <IconClock />,
    },
    {
      title: "Website-Specific Tracking",
      description: "Gain deeper control over individual sites insights.",
      icon: <IconLayoutGrid />,
    },
    {
      title: "Website Blocker",
      description: "Say goodbye to distractions and stay on track.",
      icon: <IconBan />,
    },
    {
      title: "Smart Personalization",
      description: "Categorize websites as productive or non-productive.",
      icon: <IconChartBar />,
    },
    {
      title: "Detailed Analytics",
      description:
        "Get charts & reports of your daily, weekly, or monthly screen time.",
      icon: <IconReportAnalytics />,
    },
    {
      title: "Export Data",
      description: "Download reports in Excel for deeper analysis.",
      icon: <IconDownload />,
    },
    {
      title: "AI-Driven Insights",
      description: "Smarter analytics to boost productivity. COMING SOON!",
      icon: <IconBrain />,
    },
    {
      title: "Blocked Site Limit",
      description: "Control distractions with advanced blocking. COMING SOON!",
      icon: <IconLock />,
    },
  ];

  const [showAll, setShowAll] = useState(false);
  const [isMultiColumn, setIsMultiColumn] = useState(false);

  useEffect(() => {
    const updateLayout = () => {
      setIsMultiColumn(window.innerWidth >= 768); // md breakpoint (Tailwind: `md:grid-cols-2`)
    };
    updateLayout(); // Check on mount
    window.addEventListener("resize", updateLayout);
    return () => window.removeEventListener("resize", updateLayout);
  }, []);

  const visibleFeatures =
    isMultiColumn || showAll ? features : features.slice(0, 4);

  return (
    <section id="features" className="top-full min-h-fit w-screen">
      <div className="w-screen h-full p-10 sm:p-20">
        <h2 className="text-5xl md:text-6xl font-bold text-stone-100 text-center mb-4">
          🔥 We've Got You Covered!
        </h2>
        <h4 className="text-lg text-white text-center">
          <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            PACKED WITH POWERFUL FEATURES AND MORE ON THE WAY
          </span>
        </h4>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-10 max-w-7xl mx-auto">
          {visibleFeatures.map((feature, index) => (
            <Feature key={feature.title} {...feature} />
          ))}
        </div>

        {/* Show More / Show Less Button (Only on 1 Column Layout) */}
        {!isMultiColumn && (
          // <div className="text-center mt-6">
          //   {!showAll ? (
          //     <button
          //       onClick={() => setShowAll(true)}
          //       className="px-6 py-2 rounded-full text-white bg-[#bdcf32] hover:bg-[#a4b72c] transition-all"
          //     >
          //       Show More
          //     </button>
          //   ) : (
          //     <button
          //       onClick={() => setShowAll(false)}
          //       className="px-6 py-2 rounded-full text-white bg-[#ff3b3b] hover:bg-[#cc2c2c] transition-all"
          //     >
          //       Show Less
          //     </button>
          //   )}
          // </div>
          <div className="flex justify-center mt-6">
            <button
              className="flex items-center text-white border border-white rounded px-4 py-2 transition duration-300 hover:bg-gradient-to-r  hover:text-md"
              onClick={() => setShowAll((prev) => !prev)}
            >
              {showAll ? (
                <>
                  <FaChevronUp className="mr-2" />
                  Show Less
                </>
              ) : (
                <>
                  <FaChevronDown className="mr-2" />
                  Show More
                </>
              )}
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

const Feature = ({ title, description, icon }) => {
  return (
    <div className="flex flex-col items-start p-6 border border-neutral-800 bg-black/80 rounded-lg">
      <div className="mb-4 text-neutral-400">{icon}</div>
      <div className="text-lg font-bold text-neutral-100">{title}</div>
      <p className="text-base text-neutral-300">{description}</p>
    </div>
  );
};
