import { Carousel } from "../Components/ui/carousel.jsx";
import banner1 from "../media/banner1.png";
import banner2 from "../media/banner2.png";
import banner3 from "../media/banner3.png";
import banner4 from "../media/banner4.png";
import banner5 from "../media/banner5.png";

export function CarouselFinal() {
  const slideData = [
    {
      //   title: "Mystic Mountains",
      //   button: "Explore Component",
      //   src: { banner1 },
      src: banner1,
    },
    {
      //   title: "Urban Dreams",
      //   button: "Explore Component",
      src: banner2,
    },
    {
      //   title: "Ocean Blues",
      //   button: "Explore Component",
      src: banner3,
    },
    {
      //   title: "Desert Heat",
      //   button: "Explore Component",
      src: banner4,
    },
    {
      //   title: "Forest Greens",
      //   button: "Explore Component",
      src: banner5,
    },
  ];

  //   console.log("slideData", slideData);

  return (
    <section
      id="carousel"
      className="top-full min-h-fit w-screen"
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        transition: "transform 0.5s ease-out",
      }}
    >
      <div className="w-screen h-full p-10 sm:p-20">
        <h2 className="text-5xl md:text-6xl font-bold text-stone-100 text-center mb-4 drop-shadow-[0_2.2px_2.2px_rgba(0,0,0,0.9)]">
          Unlock the Power of Productivity🚀
        </h2>
        <h4 className="text-lg text-white text-center">
          <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#bdcf32] to-[#5c8500]">
            EXPLORE THE CUTTING-EDGE FEATURES DESIGNED TO BOOST FOCUS AND
            EFFICIENCY.
          </span>
        </h4>
        <div className="relative overflow-hidden w-full h-full py-20">
          <Carousel slides={slideData} />
        </div>
      </div>
    </section>
  );
}
